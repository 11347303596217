import React from "react";
import classNames from "classnames";
import styles from "./Queestions.module.scss";
import Button from "../ui/Button/Button";

interface IAccordion {
  id?: string;
  title: string;
  children: JSX.Element;
}

function Accordion({ title, children }: IAccordion) {
  const [isOpen, setOpen] = React.useState(false);
  return (
    <div className={styles.accordionWrapper}>
      <div
        className={classNames(styles.accordionTitle, isOpen ? styles.open : "")}
        onClick={() => setOpen(!isOpen)}
      >
        {title}{" "}
        <span className={classNames(isOpen ? styles.rotate : styles.plus)}>
          {">"}
        </span>
      </div>
      <div
        className={classNames(
          styles.accordionItem,
          !isOpen ? styles.collapsed : ""
        )}
      >
        <div className={styles.accordionСontent}>{children} </div>
      </div>
    </div>
  );
}

interface IFaq {
  id: number;
  question: string;
  answer: string;
}
interface IQuestions {
  faq: IFaq[];
  title: string;
  subtitle: string;
}
function Questions({
  faq: faq,
  title = "Часто задаваемые вопросы",
  subtitle = "dsd",
}: IQuestions) {

  const lang  = localStorage.getItem("lang");

  return (
    <div className={styles.root}>
      <div id="faq" className={styles.container}>
        <div className={styles.title}>
          {title}
          <div className={styles.color}> &nbsp; {subtitle}</div>
        </div>
        <div className={styles.wrapper}>
          {faq
            .filter((item) => item.question !== "")
            .map(({ question, answer }, index) => (
              <Accordion title={question}>
                <div className={styles.accordionItem}>
                  <div className={styles.accordionСontent}>{answer}</div>
                </div>
              </Accordion>
            ))}
        </div>
       
    </div>
    <a href="/files/csbcf_qa.zip"  ><Button  className={styles.button} middle >More </Button></a>
    </div>
  );
}

export default Questions;
