import React, { ReactNode } from "react";
import classNames from 'classnames'
import styles from './Button.module.scss'

interface ButtonProps {
	children: ReactNode
	className?: string
	tagName?: 'a' | 'button' | 'link'
	background?: 'transparent' | 'white' | 'primary' | 'more'
	color?: 'white' | 'black' | 'secondary'
	size?: 100 | 'default'
	padding?: 16 | 12 | 10
	withBorder?: 'white' | 'primary' | 'black' | 'gray' | 'secondary'
	onclick: any
	[key: string]: any
}

const Button = React.forwardRef(({
	className = '',
	children = '',
	disabled = false,
	active = false,
	small = false,
	BanerRegistration = false,
	flex = false,
	start = false,
	paddingWidth = '',
	circle = false,
	beforeIcon = null,
	middle = false,
	tiny = false,
	afterIcon = null,
	type = 'contained',
	onClick = () => { }

}: ButtonProps) => {

	const handleClick = () => {
		!disabled && onClick()
	}

	return (
		<button
			className={classNames(
				className,
				styles.root,
				styles[type],
				type === "outLine" && styles.outLine,
				active && styles.active,
				disabled && styles.disabled,
				small && styles.small,
				BanerRegistration && styles.BanerRegistration,
				middle && styles.middle,
				tiny && styles.tiny,
				children === '' && styles.clean_icon,
				circle && styles.circle,
				paddingWidth && styles[`padding_${paddingWidth}`],
			)}
			onClick={handleClick}
		> <div className={styles.content}>
				{children}
			</div>
		</button>
	)
})

export default Button
