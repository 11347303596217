import classes from './list.module.scss'
import { Card } from '../Card'
import { useQuery } from '@tanstack/react-query'
import { getFetcher } from '../../../../shared/api/fetcher/getFetcher'
import { useEffect } from 'react'
import { IGalleryData } from '../../model/Gallery'

interface Props {
	selectedCategory: string | null
}
export const List = () => {
	const { data, isError, isLoading } = useQuery<IGalleryData>({
		queryKey: ['gallery'],
		queryFn: () => {
			return getFetcher(`/gallery/get`)
		}
	})

	if (isError || isLoading) {
		return null
	}

	return <div className={classes.items}>
		{data?.data.data.gallery.map((item) =>
			<Card key={item.id} item={item} />
		)}
	</div>
}