export function getBearerToken(): string | null {
  if (typeof window !== "undefined") {
			let lang = localStorage.getItem("lang")
			if(lang == 'GB') {
				return 'gb'
			} else {
				return 'ru'
			} 
  }
  return null;
}
