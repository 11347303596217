import financingIcon from "./assets/icon/financing.svg";
import locale from "../assets/icon/locale.svg";
import staff from "../assets/icon/staff.svg";
import turnover from "../assets/icon/turnover.svg";

export  const faq = [
  {
    id: 1,
    question: `Can proposal exceed 7 pages in order to add more
    information regarding the business?`,
    answer: `No, the narrative proposal cannot exceed 7 pages, excluding
    other supporting documents as outlined in the proposal
    guidance document. An applicant can provide links to relevant
    publications as optional content. However, assessment will be
    undertaken based on the mandatory information provided in the
    application form.`,
  },
  {
    id: 2,
    question: `How payments will be made to the business under the
    grant agreement?`,
    answer: `Payments are proposed to be on a milestone/activity basis.
    However, based on the type of the project and cash flow
    requirements, the payment schedule is subject to mutual
    discussion and agreement.`,
  },
  {
    id: 15,
    question: `What would be considered ineligible?`,
    answer: `For the list on ineligible actors and expenses please refer to the proposal guidance document`,
  },
  {
    id: 16,
    question: `Are partnerships eligible?`,
    answer: `Yes, partnerships are eligible, but they need to submit the partnership deed.`,
  },
];
