const APPLICATION_EN = 'Application';
const APPLICATION_RU =  'Приложение';
const WRITE_EMAIL = 'Write Email';
const WRITE_EMALRU = 'Написать письмо'
const DOWNLOAD = "Download";
const DOWNLOAD_RU = "Скачать"
const TAJIKISTAN = "Tajikistan";
const TAJIKISTAN_RU = "Таджикистан"
const PAKISTAN = "Pakistan";
const PAKISTAN_RU = "Пакистан"
const INDIA = "India";
const INDIA_RU  = "Индия"
const CALL = "To call";
const CALL_RU = "Позвонить"
const NAME = "First Name";
const NAME_RU = "Имя";
const EMAIL = "Email";
const EMAIL_RU = "Письмо";
const COMMENT = "Comment";
const COMMENT_RU = "Комментарий"
const SUBMIT = "Submit";
const SUBMIT_RU = "Отправить"
const ABOUT = "About";
const ABOUT_RU = "О нас"
const NEWS = "News"
const NEWS_RU = "Новости"
const CRITERIA = "Selection Criteria"
const CRITERIA_RU = "Критерии"
const HOW_TO_APPLY = "How to apply";
const HOW_TO_APPLY_RU = "Как подать заявку"
const PARTNERS = "Partners";
const PARTNERS_RU = "Партнёры";
const FAQ = "FAQ";
const FAQ_RU = "ЧАВО"




const CONTANS = Object.freeze(
    Object.seal({
        APPLICATION_EN,
        APPLICATION_RU,
        WRITE_EMAIL,
        WRITE_EMALRU,
        DOWNLOAD,
        DOWNLOAD_RU,
        TAJIKISTAN,
        TAJIKISTAN_RU,
        PAKISTAN,
        PAKISTAN_RU,
        INDIA,
        INDIA_RU,
        CALL,
        CALL_RU,
        NAME,
        NAME_RU,
        EMAIL,
        EMAIL_RU,
        COMMENT,
        COMMENT_RU,
        SUBMIT,
        SUBMIT_RU,
        ABOUT,
        ABOUT_RU,
        NEWS,
        NEWS_RU,
        CRITERIA,
        CRITERIA_RU,
        HOW_TO_APPLY,
        HOW_TO_APPLY_RU,
        PARTNERS,
        PARTNERS_RU,
        FAQ,
        FAQ_RU
    
    })
)

export default CONTANS;