
import { cardCriteriaData } from "./contactCardData";
import {cardCriteriaDataRU} from './cardCriteriaDataRU'
import { faq } from "./faqData";
import { faqRU } from "./faqRu";

const DATA = Object.freeze(
    Object.seal({
        cardCriteriaData,
        cardCriteriaDataRU,
        faq,
        faqRU
    })
)

export default DATA;