
import Title from "../ui/Title/Title";
import styles from "./Smart.module.scss";
import image from "../../assets/img/01.png";
import Button from "../ui/Button/Button";
import Modal from "../ui/Modal/Modal";
import {useState} from 'react'
import ModalContacts from "../../modal/ModalContacts";
import { Link } from "react-scroll";

interface ISmart {
    title: string,
    description: string,
    img: string,
    subtitle: string,
};
function Smart({ title, description, subtitle }: ISmart) {
  const [isModal, setModal] = useState(false)
  const onClose = () => setModal(false)
    return (
      <div>
    <main   className={styles.container}>
    <div className={styles.left}>
          <Title title={title} subtitle={subtitle} description={description} />
         </div>  
         <img  className={styles.img} src={image} alt="Climate Smart" /> 
    </main>
    <Modal
                visible={isModal}
                title="Application"
                content={<ModalContacts/>}
                footer={<button onClick={onClose}>Закрыть</button>}
                onClose={onClose}
            />
    </div>
    );

}

export default Smart;
