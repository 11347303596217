import { useState } from 'react'
import styles from './Apply.module.scss';

import Button from '../ui/Button/Button';
import applyImage from '../../assets/icon/apply.svg';
import { Link } from 'react-scroll';
import ModalDownload from '../ui/мodalDownload/ModalDownload';
import CONTANS from '../../constans';

const applyData = {
	title: 'How to ',
	titleRU: "Как подать ",
	subtitle: 'apply',
	subtiitleRU: "заявку",
	description: `Prospective applicants can find the complete proposal package here and submit it via email at climate.facility@akdn.org. Applications can be submitted in English with the subject “Name of Business_country of Applicant.” This round of Call for Proposal (December 07-29, 2023) is only open to submissions from Pakistan.`,
	descriptionRU: `Вы можете скачать шаблоны проектного предложения здесь. Ваше предложение, состоящее из заполненного шаблона проектного предложения и бюджета, должно быть составлено на русском или английском языке и подано не позднее 1 июня 2023 года года по электронной почте на climate.facility@akdn.org с обязательным указанием «Название компании_ Страна заявителя» в теме письма.`
}

export default function Apply() {
	const [isModal, setModal] = useState(false)
	const onClose = () => setModal(false)
	const lang = localStorage.getItem("lang");
	return (
		<div>
			<ModalDownload
				visible={isModal}
				onClose={onClose} />

			<div id='apply' className={styles.container}>
				<div className={styles.wrap}>
					<div className={styles.s}>
						<div className={styles.title}>
							{lang === "RU" ? applyData.titleRU : applyData.title}
							<span className={styles.titleColor}>{lang === "RU" ? applyData.subtiitleRU : applyData.subtitle} </span>
						</div>
						<div className={styles.text}>
							{lang === "RU" ? applyData.descriptionRU : applyData.description}
							<div />
						</div>
					</div>
					{/* <Title title= {applyData.title}subtitle={applyData.subtitle} description = {applyData.description}  /> */}
					<div className={styles.button}>

						<Button onClick={() => setModal(true)} middle className={styles.app} >
							{lang === "RU" ? "Шаблоны проектного предложения" : CONTANS.APPLICATION_EN}
						</Button>
						<Link
							activeClass="active"
							to="contacts"
							spy={true}
							smooth={true}
							offset={-70}
							duration={900}
						>
							<Button middle type="outLine" className={styles.button} >
								{lang === "RU" ? CONTANS.WRITE_EMALRU : CONTANS.WRITE_EMAIL}
							</Button>
						</Link>
					</div>
					<img className={styles.imgMobile} src={applyImage} alt="How to apply" />
				</div>
				<img className={styles.img} src={applyImage} alt="How to apply" />
				<div className={lang === "RU" ? styles.buttonMobileRu : styles.buttonMobile}>

					<Button onClick={() => setModal(true)} middle className={styles.app} >
						{lang === "RU" ? "Шаблоны проектного предложения" : CONTANS.APPLICATION_EN}
					</Button>

					<Link
						activeClass="active"
						to="contacts"
						spy={true}
						smooth={true}
						offset={-70}
						duration={900}
					><Button middle type="outLine" className={lang === "RU" ? styles.write : styles.buttonMobile} >
							{lang === "RU" ? CONTANS.WRITE_EMALRU : CONTANS.WRITE_EMAIL}
						</Button>
					</Link>
				</div>

			</div>

		</div>

	)
}
