import { Sanitize } from '../../../../shared/ui/Sanitize'
import classes from './slug.module.scss'
import { Breakpoints } from '../../../../shared/ui/Breakpoints'
import Contacts from '../../../Contacts'
import { Section } from '../Section'
import { getFetcher } from '../../../../shared/api/fetcher/getFetcher'
import { INews } from '../../model/News'
import { useParams } from 'react-router-dom'
import { useQuery } from '@tanstack/react-query'
import { useEffect } from 'react'
import { CheckContent } from '../CheckContent'

export const Slug = () => {
	const lang = localStorage.getItem("lang");
	useEffect(() => {
		if (lang) {

		} else {
			localStorage.setItem("lang", 'RU')
		}
	}, [0])
	const breakpointsData = {
		data: [
			{
				lang_id: 'RU',
				items: [
					{
						name: 'Главная',
						link: '/',
					}, {
						name: 'Новость',
						link: '/news',
					}
				]
			},
			{
				lang_id: "GB",
				items: [
					{
						name: 'Main',
						link: '/',
					}, {
						name: 'News',
						link: '/news',
					}
				]
			}
		]
	}
	const { id } = useParams()


	const { data, isError, isLoading } = useQuery<{ data: INews }>({
		queryKey: ['news', id],
		queryFn: () => {
			return getFetcher(`/news/get-by-id/${id}`)
		}
	})

	useEffect(() => {
		window.scrollTo(0, 0)
	}, [id])



	if (isError || isLoading) {
		return null
	}

	let BreakpointsFilter = breakpointsData.data.filter((el) => el.lang_id == lang)

	return <div className={classes.wrapper} >
		<div className={classes.header} >
			{BreakpointsFilter[0] && <Breakpoints items={BreakpointsFilter[0]} />}
		</div>
		<div className={classes.body}>
			<h1 className={classes.title}>
				{data?.data.title}
			</h1>
			<div className={classes.block_img}>
				{data?.data && <CheckContent id={data?.data.id} link_video={data?.data.link_video} img={data?.data.img} />}
			</div>
			<div className={classes.content} >
				{data?.data.content && <Sanitize text={data?.data.content} />}
			</div>
		</div>
		<Section />
		<Contacts />
	</div>
}

