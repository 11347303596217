import { useParams } from 'react-router-dom'
import classes from './profiles.module.scss'
import { useEffect, useState } from 'react';
import { Breakpoints } from '../../../../shared/ui/Breakpoints';
import { getFetcher } from '../../../../shared/api/fetcher/getFetcher';
import { useQuery } from '@tanstack/react-query'
import { IProject, IProjectData } from '../../model/project';
import { Sanitize } from '../../../../shared/ui/Sanitize';
import { Card } from '../Card/Card';
import Title from '../../../ui/Title';

export const ProfilesSlug = () => {
    const { id } = useParams()
    const lang = localStorage.getItem("lang");
    const { data, isError, isLoading } = useQuery<{ data: IProject }>({
		queryKey: ['project', id],
		queryFn: () => {
			return getFetcher(`/project/get-by-id/${id}`)
		}
	})

    const { data: projects } = useQuery<IProjectData>({
		queryKey: ['projects'],
		queryFn: () => {
			return getFetcher(`/project/get`)
		}
	})

	useEffect(() => {
		if (lang) {

		} else {
			localStorage.setItem("lang", 'RU')
		}
	}, [0])

    const breakpointsData = {
		data: [
			{
				lang_id: 'RU',
				items: [
					{
						name: 'Главная',
						link: '/',
					}, {
						name: 'Проект',
						link: '/news',
					}
				]
			},
			{
				lang_id: "GB",
				items: [
					{
						name: 'Main',
						link: '/',
					}, {
						name: 'Projects',
						link: '/projects/1',
					}
				]
			}
		]
	}
    if(isError && isLoading) {
        return null
    }

    let BreakpointsFilter = breakpointsData.data.filter((el) => el.lang_id == lang)
    return <div className={classes.wrapper} >
    <div className={classes.header} >
        {BreakpointsFilter[0] && <Breakpoints items={BreakpointsFilter[0]} />}
    </div>
    <div className={classes.bl_title} >
        <h1 className={classes.title}>
            {data?.data.title}
        </h1>
    </div>
    <div className={classes.bl_img} >
        <img 
            src={data?.data.img} 
            width={50}
            height={50}
            alt='adasdasd'
            />
    </div>
    <div className={classes.body}>
        <div className={classes.content} >
            {data?.data.description && <Sanitize text={data?.data.description} />}
        </div>
    </div>
    <Title className={classes.section_title} title={lang === "RU" ? "СГБс" : "SGBs "} subtitle={lang === "RU" ? "Профиль" : "Profiles "} first />
    <div className={classes.items} >
        {projects?.data.data.project.map((item) => <Card project={item} />)}
    </div>
</div>
}