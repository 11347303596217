import Title from '../../../ui/Title'
import { List } from '../List'
import classes from './news.module.scss'

export const Gallery = () => {
	const lang = localStorage.getItem("lang");

	return <div className={classes.wrapper}>
		<Title title={lang  == 'RU' ? 'Климатическая галерея' :'Climate Gallery' }  />
		<List />
	</div>
}
