import React from "react";
import styles from "./Contacts.module.scss";
import mail from "../../assets/icon/mail.svg";
import phone from "../../assets/icon/phone.svg";
import Button from "../ui/Button/Button";
import { Formik, Field, Form, FormikHelpers, ErrorMessage } from "formik";
import { useState } from "react";
import ModalContacts from "../../modal/ModalContacts";
import Modal from "../ui/Modal/Modal";
import * as Yup from "yup";
import axios from "axios";
import CONTANS from "../../constans";

interface Values {
  Name: string;
  email: string;
  text: string;
}

const validationSchema = Yup.object({
  Name: Yup.string().required(),
  email: Yup.string().email().required(),
  text: Yup.string().required(),
});

export default function Contacts() {
  const [isModal, setModal] = useState(false);
  const [isDone, setDone] = useState(false);
  const onClose = () => setModal(false);
  const onCloseDone = () => setDone(false);

  const lang = localStorage.getItem("lang");

  return (
    <div id="contacts" className={styles.container}>
      <div className={styles.wrapContact}>
        <div className={styles.title}>
          {lang === "RU" ? "Наши контакты" : "Contact us"}
        </div>
        <a className={styles.link} href="mailto: climate.facility@akdn.org">
          <div className={styles.mail}>
            <img src={mail} alt="mail" />
            climate.facility@akdn.org
          </div>
        </a>
        <a className={styles.link} href="tel:+992372218406">
          <div className={styles.mail}>
            <img src={phone} alt="phone" />+ (99237) 2247650, 2218406,{" "}
            {lang === "RU" ? CONTANS.TAJIKISTAN_RU : CONTANS.TAJIKISTAN}
          </div>
        </a>
        <a className={styles.link} href="tel:+92511112532534">
          <div className={styles.mail}>
            <img src={phone} alt="phone" />
            +92 51 111253254 Ext (150), &nbsp;
            {lang === "RU" ? CONTANS.PAKISTAN_RU : CONTANS.PAKISTAN}
          </div>
        </a>
        <a className={styles.link} href="tel:++9199204626686">
          <div className={styles.mail}>
            <img src={phone} alt="phone" />
            +91 99204626686,+91 1147399700 (ext. 38),&nbsp;
            {lang === "RU" ? CONTANS.INDIA_RU : CONTANS.INDIA}
          </div>
        </a>
        <div className={styles.button}>
          <a href="mailto: climate.facility@akdn.org">
            <Button middle className={styles.app}>
              {lang === "RU" ? CONTANS.WRITE_EMALRU : CONTANS.WRITE_EMAIL}
            </Button>
          </a>
          <a href="tel:+992372247650">
            {" "}
            <Button middle type="outLine" className={styles.button}>
              &nbsp;&nbsp;&nbsp;{" "}
              {lang === "RU" ? CONTANS.CALL_RU : CONTANS.CALL}{" "}
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </Button>
          </a>
        </div>
      </div>
      <div className={styles.wrapForm}>
        <Formik
          //  validationSchema={validationSchema}
          initialValues={{
            Name: "",
            email: "",
            text: "",
          }}
          onSubmit={(values: Values) => {
            axios({
              method: "POST",
              url: "https://climatefacility.bobo.tj",
              data: values,
            })
              .then(function (res) {
                setDone(true);
              })
              .catch(function (res) {
                console.log(res);
              });
          }}
        >
          <Form className={styles.form}>
            <div className={styles.formItem}>
              <label htmlFor="Name">
                {lang === "RU" ? CONTANS.NAME_RU : CONTANS.NAME}
              </label>
              <Field required id="Name" name="Name" placeholder=
              {lang === "RU" ? "Анатолий" : "Jack"} />
            </div>
            <div className={styles.formItem}>
              <label htmlFor="email">
                {lang === "RU" ? CONTANS.EMAIL_RU : CONTANS.EMAIL}
              </label>
              <Field
                required
                type="email"
                id="email"
                name="email"
                placeholder="envkt@example.com"
              />
            </div>
            <div className={styles.formItem}>
              <label htmlFor="comment">
                {" "}
                {lang === "RU" ? CONTANS.COMMENT_RU : CONTANS.COMMENT}
              </label>
              <Field
                width={500}
                heigth={200}
                id="comment"
                name="comment"
                placeholder={lang === "RU" ?  "Введите здесь" : "Type here"}
                type="text"
                as="textarea"
              />
            </div>
            <Button className={styles.button} tiny>
            {lang === "RU"? CONTANS.SUBMIT_RU : CONTANS.SUBMIT}  
            </Button>
          </Form>
        </Formik>
      </div>
      <Modal
        visible={isModal}
        title="Application"
        content={<ModalContacts />}
        footer={<button onClick={onClose}>Закрыть</button>}
        onClose={onClose}
      />

      <Modal
        visible={isDone}
        title="Application"
        content={<h1>Done</h1>}
        footer={<button onClick={onCloseDone}>Закрыть</button>}
        onClose={onCloseDone}
      />
    </div>
  );
}
