import classes from './section.module.scss'
import { Pagination} from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';
import { IGalleryData } from '../../model/Gallery';
import { getFetcher } from '../../../../shared/api/fetcher/getFetcher';
import { useQuery } from '@tanstack/react-query';
import Title from '../../../ui/Title';
import { Link } from 'react-router-dom';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import clsx from 'clsx';

export const Section = () => {
	const lang = localStorage.getItem("lang");

	const { data, isError, isLoading } = useQuery<IGalleryData>({
		queryKey: ['gallery-section', lang],
		queryFn: () => {
			return getFetcher(`/gallery/get`)
		}
	})
	
	return <div
		className={classes.section}
		id={'gallery'}
	>
		<div className={classes.left} >
			<div className={classes.info} >
				<Title 
					title={lang === "RU" ? 'Климатического комплекса' : 'The Climate Facility Gallery'}
					subtitle={lang === "RU" ? 'Галерея' : 'Gallery'}
					description={lang === "RU" ? 'Наша галерея — это платформа, на которой вы можете увидеть вдохновляющие изображения и истории, связанные с экологическими инициативами и климатическими проектами, реализуемыми по всему миру.' : 'Our gallery is a platform where you can see inspiring images and stories related to environmental initiatives and climate projects taking place around the world.'}
				/>
			</div>
			<Link to={'/gallery'} className={classes.button} >
				{lang === "RU" ? 'Показать все' : 'Show more'}
			</Link>
		</div>
		<div className={classes.right} >
			<Swiper
				className={classes.swiper}
				slidesPerView={1}
				modules={[Pagination]}
				spaceBetween={20}
				pagination={{
					enabled: true,
					clickable: true,
				  }}
			>
				{data?.data.data.gallery.map((item) =>
					<SwiperSlide key={item.id}>
						<div className={classes.bl_img} >
							<img 
							src={item.img}
							width={50}
							height={50}
							alt={'asd'}
							/>
						</div>
					</SwiperSlide>
				)}
			</Swiper>
		</div>

	</div>
}