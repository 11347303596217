import classes from './card.module.scss'
import { Link } from 'react-router-dom'
import { Sanitize } from '../../../../shared/ui/Sanitize'
import { IGallery } from '../../model/Gallery'
import GalleryModal from '../GalleryModal'
import { useState } from 'react'

interface Props {
	item: IGallery
}

export const Card = ({ item }: Props) => {
	const description = item.title.slice(0, 150)
	const [open, setOpen] = useState(false)
	return <div
		className={classes.item}
		key={item.id}>
		<GalleryModal
			open={open}
			pictures={[...[{id: 0, img: item.img}] ,...item.images_list.map(({img, id}) => ({id, img: img}))]}
			close={() => setOpen(false)}
      	/>
		<div className={classes.item_body} >
			<div className={classes.img_body} onClick={() => {
                setOpen(true)
              }} >
				<img
					className={classes.img}
					src={item.img}
					alt={item.title} />
			</div>
			<h3 className={classes.title}>
				{item.title}
			</h3>
			{/* <p className={classes.text}>
				<Sanitize text={description} />
			</p> */}
			{/* <Link
				className={classes.link}
				to={`/news/${item.id}`}></Link> */}
		</div>
	</div>
}