import React  from 'react'
import { Formik, Field, Form } from "formik";
import Button from '../components/ui/Button/Button';
import styles from './ModalContacts.module.scss'
import axios from 'axios';

interface Values {
    Name: string;
    email: string;
    comment: string;
  }

export default function ModalContacts() {

  return (
    <div className={styles.wrapForm}>
    <Formik
      initialValues={{
        Name: "",
        email: "",
        comment: "",
      }}
      onSubmit={(
        values: Values,
      ) => {
        axios({
          method: 'POST',
          url: 'https://climatefacility.bobo.tj',
          data: values
        })
          .then(function (res) {
             alert('Successfully'); 
          })
          .catch(function (res) {
             console.log(res)
        });
      }}
    >
      <Form className={styles.form}>
        <div className={styles.formItem}>
          <label htmlFor="Name">First Name</label>
          <Field  required id="Name" name="Name" placeholder="John" />
        </div>
        <div className={styles.formItem}>
          <label htmlFor="email">email</label>
          <Field type="email" required id="email" name="email" placeholder="envkt@example.com" />
        </div>
        <div className={styles.formItem}>
          <label  htmlFor="comment">Comment</label>
          <Field
            required
            width={500}
            heigth={200}
            id="comment"
            name="comment"
            placeholder="Type here"
            as="textarea"
          />
        </div>
        <Button middle className={styles.button}>
          Submit
        </Button>
      </Form>
    </Formik>
  </div>
  )
}
