import classes from './card.module.scss'
import { INews } from "../../model/News"
import { Link } from 'react-router-dom'
import { Sanitize } from '../../../../shared/ui/Sanitize'

interface Props {
	newsItem: INews
}

export const Card = ({ newsItem }: Props) => {
	const description = newsItem.content.slice(0, 150)

	return <div
		className={classes.item}
		key={newsItem.id}>
		<div className={classes.item_body} >
			<div className={classes.img_body}>
				<img
					className={classes.img}
					src={newsItem.img}
					alt={newsItem.title} />
			</div>
			<h3 className={classes.title}>
				{newsItem.title}
			</h3>
			<p className={classes.text}>
				{description.replace(/<[^>]*>/g, '')}
			</p>
			<Link
				className={classes.link}
				to={`/news/${newsItem.id}`}></Link>
		</div>
	</div>
}