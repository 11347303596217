import classes from './list-mobile.module.scss'
import { ICategoryData, INews, INewsData } from "../../model/News"
import { Swiper, SwiperSlide } from 'swiper/react';

import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import { Card } from '../Card';
import { getFetcher } from '../../../../shared/api/fetcher/getFetcher';
import { useQuery } from '@tanstack/react-query';
import { useEffect } from 'react';

interface Props {
	selectedCategory: string | null
}
export const ListMobile = ({ selectedCategory }: Props) => {

	const { data, isError, isLoading, refetch } = useQuery<ICategoryData>({
		queryKey: ['news', selectedCategory],
		queryFn: () => {
			return getFetcher(`/category/get-by-id/${selectedCategory}`)
		},
		enabled: false
	})


	useEffect(() => {
		if (selectedCategory) { refetch() }
	}, [selectedCategory])

	if (isError || isLoading) {
		return null
	}


	return <div>
		<Swiper
			slidesPerView={1.5}
			breakpoints={{
				768: {
					slidesPerView: 3,
				},
			}}

		>
			{data?.data[0].news.map((item) =>
				<SwiperSlide key={item.id}>
					<Card newsItem={item} />
				</SwiperSlide>
			)}
		</Swiper>


	</div >
}